export const localesCommon = {
    customDomain: 'CUSTOM_DOMAIN',
    dashboard: 'DASHBOARD.DEFAULT',
    delete: 'DELETE.DEFAULT',
    description: 'DESCRIPTION',
    deleteMessage: 'DELETE.MESSAGE',
    earlyAccess: 'EARLY_ACCESS',
    email: 'EMAIL',
    enterEmail: 'ENTER_EMAIL',
    emailAddress: 'EMAIL_ADDRESS',
    hey: 'HEY',
    logoutMessage: 'LOGOUT_MESSAGE',
    confirm: 'CONFIRM',
    of: 'OF',
    or: 'OR',
    and: 'AND',
    example: 'EXAMPLE',
    noImage: 'NO_IMAGE',
    newLink: 'NEW_LINK',
    goToResponse: 'GO_TO_RESPONSE',
    poweredBy: 'POWERED_BY',
    privacyPolicy: {
        title: 'PRIVACY_POLICY.TITLE',
        description: 'PRIVACY_POLICY.DESCRIPTION'
    },
    settings: 'SETTINGS',
    slug: 'SLUG.DEFAULT',
    step: 'STEP',
    save: 'SAVE',
    termsOfServices: {
        title: 'TERMS_OF_SERVICES.TITLE',
        description: 'TERMS_OF_SERVICES.DESCRIPTION'
    },
    share: 'SHARE',
    imageSizeRestriction: 'IMAGE_SIZE_RESTRICTION',
    becomeABetterCollector: 'BECOME_A_BETTER_COLLECTOR',
    image: 'IMAGE',
    an: 'AN',
    updated: 'UPDATED',
    untitled: 'UNTITLED',
    status: 'STATUS',
    updateYourProfileImage: 'UPDATE_YOUR_PROFILE_IMAGE',
    removeInvitationFor: 'REMOVE_INVITATION_FOR',
    remove: 'REMOVE',
    removeWarningMessage: 'REMOVE_WARNING_MESSAGE',
    basicInformation: 'BASIC_INFORMATION',
    advance: 'ADVANCE',
    manage: 'MANAGE',
    readMore: 'READ_MORE',
    noDescription: 'NO_DESCRIPTION',
    domain: 'DOMAIN',
    hidden: 'HIDDEN',
    public: 'PUBLIC',
    lastSubmittedAt: 'LAST_SUBMITTED_AT',
    linkToPrivacyPolicy: 'LINK_TO_PRIVACY_POLICY',
    linkToTermsOfServices: 'LINK_TO_TERMS_OF_SERVICES',
    shareYourWorkspace: 'SHARE_YOUR_WORKSPACE',
    deletionRemaining: 'DELETION_REMAINING',
    forms: 'FORMS',
    respondersAndGroups: 'RESPONDERS_AND_GROUPS',
    noGroupFound: 'NO_GROUP_FOUND',
    chooseYourForm: 'CHOOSE_YOUR_FORM',
    added: 'ADDED'
};
