export const signInScreen = {
    loginTitleSlogan: 'SIGNIN_SCREEN.LOGIN_TITLE_SLOGAN',
    readyToGetStarted: 'SIGNIN_SCREEN.READY_TO_GET_STARTED',
    reviewForms: 'SIGNIN_SCREEN.REVIEW_FORMS',
    collectForms: 'SIGNIN_SCREEN.COLLECT_FORMS',
    easyToManageForms: 'SIGNIN_SCREEN.EASY_TO_MANAGE_FORMS',
    deleteResponses: 'SIGNIN_SCREEN.DELETE_RESPONSES',
    welcomeMessage: 'SIGNIN_SCREEN.WELCOME_MESSAGE',
    continueWIth: 'SIGNIN_SCREEN.CONTINUE_WITH',
    signinAgreementDescription: 'SIGNIN_SCREEN.SIGNIN_AGREEMENT_DESCRIPTION',
    signUp: 'GET_STARTED.USER_IS_FIT.BUTTON_TEXT',
    welcomeBack: 'SIGNIN_SCREEN.WELCOME_BACK',
    signInToContinue: 'SIGNIN_SCREEN.SIGNIN_TO_CONTINUE',
    features: {
        title: 'SIGNIN_SCREEN.FEATURES.TITLE',
        description: 'SIGNIN_SCREEN.FEATURES.DESCRIPTION',
        otp_title: 'SIGNIN_SCREEN.FEATURES.OTP_TITLE',
        otp_description: 'SIGNIN_SCREEN.FEATURES.OTP_DESCRIPTION'
    },
    signIn: 'SIGNIN_SCREEN.SIGN_IN',
    emailInputLabel: 'SIGNIN_SCREEN.EMAIL_INPUT_LABEL',
    enterYourEmail: 'SEND_CODE.TITLE',
    sendCodeButton: 'SIGNIN_SCREEN.SEND_CODE_BUTTON',
    signInButton: 'SIGNIN_SCREEN.SIGN_IN_BUTTON',
    orSignInUsing: 'SIGNIN_SCREEN.OR_SIGN_IN_USING',
    verificationTitle: 'SIGNIN_SCREEN.VERIFICATION.TITLE',
    verificationDescription: 'SIGNIN_SCREEN.VERIFICATION.DESCRIPTION',
    enterOtpCode: 'SIGNIN_SCREEN.ENTER_OTP_CODE',
    enterOtpCodePlaceholder: 'SIGNIN_SCREEN.ENTER_CODE_PLACEHOLDER',
    backButtonTitle: 'SIGNIN_SCREEN.BACK_BUTTON_TITLE',
    didNotReceiveCode: 'SIGNIN_SCREEN.DIDNOT_RECEIVE_CODE',
    descriptionInModal: 'SIGNIN_SCREEN.MODAL.DESCRIPTION'
};
